import React, { Component } from 'react';
import TitleMain from './titleMain';
import '../assets/css/downLoad.css'



class Download extends Component {

    componentDidMount = () => {
        // console.log(document.getElementsByTagName('footer')[0].style)
        document.getElementsByTagName('footer')[0].style.marginTop = 0;
    }

    componentWillUnmount = () => {

        document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }


    render() {
        return (
            <div className="downLoad">
                {/* <TitleMain name='下載' /> */}
                <div className="top">
                    <h1 style={{ fontWeight: "600" }}>离线编程软件</h1>
                    {/* <p>基于 Blockly 的积木式硬件编程工具</p> */}
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/cocorobolixianban3.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoPi 图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoRoboPiDeskTop Installer v1.0.3.exe">
                                <button>下载 CocoPi 图形化编程离线版本（v1.0.3）</button>
                            </a>
                            <span className="down_time2">更新于2024/05/22</span>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/cocorobolixianban2.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">AIoT 图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoRoboXDeskTop Installer v1.0.8.exe">
                                <button>下载 AIoT 图形化编程离线版本（v1.0.8）</button>
                            </a>
                            <span className="down_time2">更新于2024/04/09</span>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/离线版图标.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">百变智造图形化编程离线版本</span>
                        <p className="content">为了能够让用户更方便的使用该产品，在网络较慢或者其他情况下，可以更好使用。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoBlockly%20Desktop%20Installer%20v3.0.7.exe">
                                <button>下载百变智造图形化编程离线版本（v3.0.7）</button>
                            </a>
                            <span className="down_time2">更新于2024/04/09</span>
                        </div>
                    </div>
                </div>

                <h1 className="titleName">图形化编程上传插件</h1>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/6.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">百变智造图形化编程平台上传插件</span>
                        <p className="content">为了能够让百变智造图形化编程平台能够从浏览器和主控模块进行通信，我们推出一款跨平台的上传插件（Uploader），用户下载该插件并安装，随后即可访问 CocoBlockly，对我们的电子模块进行上传编程。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <div>
                                <a href="https://cocorobo.cn/downloads/CocoBlocklyUploaderInstallerv3.0.12.exe">
                                    <button>下载 Windows 版本(v3.0.12)</button>
                                </a> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="down_time">更新于2020/07/17</span>
                                <a href="https://cocorobo.cn/downloads/CocoBlocklyUploaderInstallerv3.0.12.pkg">
                                    <button>下载 MacOS 版本(v3.0.12)</button>
                                </a>
                                <span className="down_time1">更新于2020/07/24</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/CocoBlockly_X_Uploader.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">AIoT图形化编程平台上传插件</span>
                        <p className="content">为了能够让AIoT图形化编程平台能够从浏览器和我们的 AI 和 IoT 模块进行通信，进行上传和运行的操作，我们推出一款跨平台的上传插件，用户下载该插件并安装，随后便可以对我们的电子模块进行上传编程。</p>
                        <p className="setting">支持的操作系统：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.0.18.exe">
                                <button>下载 Windows 版本(v1.0.18)</button>
                            </a> &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="down_time">更新于2024/03/01</span>
                            <a href="https://cocorobo.cn/downloads/CocoBlocklyXUploaderInstallerv1.1.4.pkg">
                                <button>下载 MacOS 版本(v1.1.4)</button>
                            </a>
                            <span className="down_time1">更新于2024/04/27</span>
                        </div>
                    </div>
                </div>

                <h1 className="titleName">推荐浏览器</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/3.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">下载谷歌浏览器</span>
                        <p className="content">使用谷歌浏览器，在线编程体验更流畅</p>
                        <div className="downing">
                            <a href="https://cocorobo.cn/downloads/Chrome.apk" className='android'>
                                <button>下载Android版本</button>
                            </a>
                            <a target="_blank" href="https://www.google.cn/chrome/index.html">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/7.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">360安全浏览器</span>
                        <p className="content">CocoBlockly 编程工具在 360 极速浏览器中运行，让你在线编程体验进行流畅</p>
                        <div className="downing">
                            <a target="_blank" href="https://browser.360.cn">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">辅助工具及软件</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/4.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">TeamViewer 远程协助软件</span>
                        <p className="content">随时随地连接到远程桌面电脑，让远程连接过程更加的快速和安全，轻松访问文件、网络及程序</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.teamviewer.cn/cn/download/">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/5.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ZOOM 远程会议软件</span>
                        <p className="content">多人手机云视频会议软件，提供兼备高清视频与移动网络会议功能的免费云视频通话</p>
                        <div className="downing">
                            <a target="_blank" href="https://zoom.com.cn/download">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/8.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">向日葵远程协助软件</span>
                        <p className="content">免费的集远程控制电脑手机、远程桌面连接、远程开机、远程管理、支持内网穿透的一体化远程控制管理工具软件</p>
                        <div className="downing">
                            <a target="_blank" href="https://sunlogin.oray.com/download/">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/9.jpg')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ToDesk 远程协助软件</span>
                        <p className="content">稳定流畅的远程控制,远程办公,远程协助软件.采用端对端加密,让每一次远程访问都安全可靠</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.todesk.com/download.html">
                                <button>前往下载</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download;